<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Peringatan Obat'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/img/registrants/default.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col" v-if="imgLoaded != false">
                <table class="table mb-3">
                  <tr>
                    <td><strong>Nama Klinik</strong></td>
                    <td>{{ data.clinic_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Subdomain</strong></td>
                    <td>{{ data.clinic_subdomain }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama Pendaftar</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Email</strong></td>
                    <td>{{ data.email }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Whatsapp</strong></td>
                    <td>{{ data.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Provinsi</strong></td>
                    <td>{{ data.province_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kota/Kabupaten</strong></td>
                    <td>{{ data.city_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tanggal Daftar</strong></td>
                    <td>{{ data.register_date_display }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ data.status_display }}</td>
                  </tr>
                </table>
                <button class="btn mx-1 btn-secondary" @click="$router.push('/registrants')">
                  <i class="fas fa-fw fa-arrow-left"></i> Kembali
                </button>
                <button
                  @click="
                    $router.push({
                      path: '/registrants/edit/' + data.id,
                    })
                  "
                  class="btn mx-1 btn-success"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button>
                <!-- <button class="btn mx-1 btn-danger">
                  <i class="far fa-fw fa-trash-alt"></i> Hapus
                </button> -->
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Detail",

  components: {
    Card,
  },

  data() {
    return {
      imgLoaded: true,
      data: [],
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete("registrants/" + this.$route.params.id);
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/registrants");
      }
    },

    async get() {
      this.data = await module.get("registrants/" + this.$route.params.id);
      // this.getMedicineHistory(this.data.medicine_id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/registrants");
      }
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pendaftar Medhop", route: "/registrants/" },
      { title: "Detail"},
    ]);

    this.get();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>